import React, { FC } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';

import ErrorPageTemplate from 'gatsby-theme-gaviscon/src/templates/ErrorPage/ErrorPage';

interface IPropsError404Page extends PageProps {

}
const ErrorPage: FC<IPropsError404Page> = () => {
  const umbraco404Page = useStaticQuery(graphql`
   {
    siteSettings(lang: { eq: "ar-eg" }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: "ar-eg" }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: "ar-eg" }) {
      ...FragmentHeader
    }
    footer(lang: { eq: "ar-eg"}) {
      ...FragmentFooter
    }
    page: allUmbracoError404(filter: { lang: { eq: "ar-eg" } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        error
        title {
          value
        }
        description
        menu {
          name
          url
        }
      }
    }
  }
  `);

  return <ErrorPageTemplate {...umbraco404Page} />;
};

export default ErrorPage;
